<template>
  <article class="act-expo" :class="{ mobile: isMobile }">
    <Hero
      heading="Solve your electrification needs with us"
      subheading="Learn about our customized, reliable electric mobility solutions with a simple download of our digital
          brochure."
    >
    </Hero>
    <div id="contact-form" class="l-one--has-bg l-one--padding-bottom">
      <div class="l-one__col l-one__col--wide">
        <div class="form-title">
          <h2>Submit the form below to learn more.</h2>
        </div>
        <form class="form" @submit.prevent="validate" ref="form">
          <p aria-hidden="true" class="form-legend">An asterisk (<span>*</span>) indicates a required field</p>
          <div class="inline-inputs">
            <div class="group-one">
              <label for="first_name"
                >First Name <span class="require-mark" aria-hidden="true">*</span>
                <input
                  id="first_name"
                  maxlength="55"
                  name="first_name"
                  pattern="^[A-Za-z]+(?: +[A-Za-z]+)*$"
                  title="This field only accepts letters (no trailing spaces)"
                  type="text"
                  v-model="answers['SuppliedFirstName']"
                  required
                />
              </label>
              <label for="last_name"
                >Last Name <span class="require-mark" aria-hidden="true">*</span>
                <input
                  id="last_name"
                  maxlength="55"
                  name="last_name"
                  required
                  pattern="^[A-Za-z]+(?: +[A-Za-z]+)*$"
                  title="This field only accepts letters (no trailing spaces)"
                  type="text"
                  v-model="answers['SuppliedLastName']"
                />
              </label>
            </div>
          </div>
          <label ref="email" for="email"
            >Business Email <span class="require-mark" aria-hidden="true">*</span>
            <input
              id="email"
              maxlength="55"
              name="email"
              required
              type="text"
              pattern="[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?:\.[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*@(?:[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_](?:[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_-]*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
              title="Please enter the correct email address"
              v-model="answers['SuppliedEmail']"
            />
          </label>
          <p class="form-agreement">Click ‘Submit’ to learn more about products and services from Electrify America.</p>
          <div>
            <input
              ref="submitButton"
              class="button button--submit"
              name="submit"
              type="submit"
              value="Submit"
              style="width: 200px"
            />
          </div>
          <p v-show="serverError !== ''" class="server-error">
            Error:
            <br />
            {{ serverError }}
          </p>
        </form>
      </div>
    </div>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import ApiService from '@/services/Api';

export default {
  name: 'ACTExpo',
  components: { Hero },
  metaInfo: {
    title: 'Electrify America',
    meta: [
      {
        name: 'description',
        content: '', // Missing description.
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/act-expo/' }],
  },
  data() {
    return {
      answers: {
        SuppliedFirstName: '',
        SuppliedLastName: '',
        SuppliedEmail: '',
        Source: 'ACT Expo',
      },
      serverError: '',
    };
  },
  methods: {
    async validate(e) {
      e.preventDefault();
      this.serverError = '';

      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: this.answers,
        };
        this.putACTExpo(params);
      } catch (error) {
        console.error(error);
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    putACTExpo(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.SuppliedEmail);
      ApiService.init();
      ApiService.put('/v1/salesforce/actexpo', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'thanks-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = 'There was an error submitting the form, please try again';

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
  watch: {
    serverError(newValue) {
      if (newValue !== '') {
        if (newValue.includes('Email')) {
          this.$refs.email.style.color = '#d90041';
          this.$refs.email.scrollIntoView();
        } else if (newValue.includes('Serial')) {
          this.$refs.serial.style.color = '#d90041';
          this.$refs.serial.scrollIntoView();
        }
      }
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
};
</script>

<style scoped lang="scss">
.act-expo {
  .form-title {
    padding-top: 100px;
  }
  .form-agreement {
    font-weight: 400;
  }
  .form {
    width: 90%;
    .inline-inputs {
      margin-top: 40px;
      .group-one {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1000px) {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
